import * as React from 'react';
import {createRef, RefObject} from 'react';
import {Card, Image, Message} from "semantic-ui-react";
import './MessageFromProfessor.css';
import President from '../../asset/image/main/president.jpeg';
import './MessageFromPresident.css'
import Banner2 from "../../asset/image/main/main4.png";
import Professor from "../../asset/image/main/professor.jpg";
import {MainProfile} from "./MessageFromProfessor";
import IntersectionObserverManager from "../../IntersectionObserverManager";

export default class MessageFromPresident extends React.Component<{}> {
    private intersectionObserver?: IntersectionObserverManager;
    private messageWrapper: RefObject<HTMLDivElement> | null;

    constructor(props: {}) {
        super(props);
        this.messageWrapper = createRef<HTMLDivElement>();
    }

    public componentDidMount(): void {
        if (this.messageWrapper) {
            this.setIntersectionObserverAndSetSlideRight(this.messageWrapper)
        }
    }

    public render() {
        return (
            <div className={'mainPageSectionWrapper'} style={{
                position: 'relative',
                backgroundImage: `url(${Banner2})`,
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: "fixed",
                backgroundPosition: 'center center',
            }}>
                <div ref={this.messageWrapper} className={'presidentMessageWrapper'}>
                    <MainProfile name={'정종혁'} src={President} title={'YIG 44대 회장'} />
                    <MessageExampleMessage/>
                </div>

            </div>
        )
    }


    private setIntersectionObserverAndSetSlideRight = (ref: RefObject<HTMLDivElement>) => {
        const observer = new IntersectionObserverManager(entries => {
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('slideRight');
                } else {
                    entry.target.classList.remove('slideRight');
                }
            })
        });


        this.intersectionObserver = observer;
        if (ref.current) {
            this.intersectionObserver.observe(ref.current);
        }
    };
    //
    // private renderMessage() {
    //     return (
    //         <Grid className={'presidentMessageWrapper'}>
    //             <Grid.Column>
    //                 <Segment size={"large"} className={'message-segment'}>
    //                     <Grid stackable={true}>
    //                         <Grid.Row centered={true}>
    //                             <Header as={'h1'}>MESSAGE FROM THE PRESIDENT</Header>
    //                         </Grid.Row>
    //                         <Grid.Row>
    //                             <Grid.Column width={11} floated={'right'}>
    //                                 <Header as={'h4'} color={"grey"} subheader={true}
    //                                         className={'messageFromProfessor'}>
    //                                     <p>
    //                                         YIG는 연세대학교 유일의 실전 투자 학회입니다.
    //                                     </p>
    //                                     <p>
    //                                         단순한 학술적 지식만이 아닌 리서치를 통한 실제 산업 및 기업분석, 그리고 가치 평가를 통해 주가와 시장 흐름을 예측하며 현업에 계신 Alumni 선배님들과 액팅기수들의 조언을 통해 최고의 투자 전문가를 목표로 함께 성장해 나갑니다.
    //                                      </p>
    //                                     <p>
    //                                         YIG에서 이러한 경험과 지식은 단순히 추상적으로만 남지 않습니다. 운용 중인 YIG Fund를 통하여 액팅 학회원들은 실제로 본인의 투자 전략을 시험해보거나 유망 기업에 직접 투자해볼 수 있는 기회를 가집니다.
    //                                     </p>
    //                                     <p>
    //                                         우리 학회의 또 하나의 핵심 가치는 바로 ‘사람’입니다.
    //                                         YIG 학회원들간, 그리고 300여 명의 Alumni Network간의 정과 끈끈함은 무엇보다도 특별합니다.
    //                                         저 역시 YIG에서 얻은 가장 큰 선물은 소중한 인연들이라고 생각할만큼 너무나도 좋은 학우들과 선배님들을 많이 만날 수 있었습니다.
    //                                     </p>
    //                                     <p>
    //                                         YIG의 36번째 투자자는 바로 여러분입니다. YIG는 여러분의 미래에 투자하겠습니다.
    //                                     </p>
    //                                 </Header>
    //                             </Grid.Column>
    //                             <Grid.Column width={3}>
    //                                 <PresidentProfileImage src={President} title={'YIG 35대 회장'} name={'윤지훈'}/>
    //                             </Grid.Column>
    //                         </Grid.Row>
    //                     </Grid>
    //                 </Segment>
    //             </Grid.Column>
    //         </Grid>
    //     )
    // }
}

/*
*   <div style={{
                backgroundImage: `url(${Banner4})`,
                backgroundSize: 'cover',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: "fixed",
                padding: '40px'
            }}>
                <Grid>
                    <Grid.Column>
                        <Segment size={"large"} className={'message-segment'}>
                            <Grid stackable={true}>
                                <Grid.Row centered={true}>
                                    <Header as={'h1'}>MESSAGE FROM THE PRESIDENT</Header>
                                </Grid.Row>
                                <Grid.Row >
                                    <Grid.Column width={3}>
                                        <PresidentProfileImage src={Professor} title={'YIG 35대 회장'} name={'윤지훈'}/>
                                    </Grid.Column>
                                    <Grid.Column width={11} floated={'right'}>
                                        <Header as={'h4'} color={"grey"} subheader={true}
                                                className={'messageFromProfessor'}>
                                            <p>
                                                YIG는 연세대학교 유일의 실전 가치 투자 학회입니다. 단순한 학술적 지식만이 아닌 리서치를 통한 실제 산업 및 기업분석, 그리고
                                                가치 평가를 통해 주가와 시장 흐름을 예측합니다.
                                            </p>
                                            <p>
                                                이를 통해 주식 시장 뿐만이 아니라 국내와 해외의 산업 현황, 기업 구성 및 전락에 대한 넓고 깊은 인사이트를 얻어갈 수 있으며,
                                                현업에 계신 Alumni 선배님들과 액팅기수들의 조언을 통해 최고의 투자 전문가를 목표로 함께 성장해 나갑니다. </p>
                                            <p>
                                                YIG에서 이러한 경험과 지식은 단순히 추상적으로만 남지 않습니다. 운용 중인 YIG Fund를 통하여 액팅 학회원들은 실제로
                                                본인의 투자 전략을 시험해보거나 유망 기업에 직접 투자해볼 수 있는 기회를 가집니다.
                                            </p>
                                            <p>
                                                우리 학회의 또 하나의 핵심 가치는 바로 ‘사람’입니다. YIG 학회원들간, 그리고 300여 명의 Alumni Network간의
                                                정과 끈끈함은 무엇보다도 특별합니다. 저 역시 YIG에서 얻은 가장 큰 선물은 소중한 인연들이라고 생각할만큼 너무나도 좋은
                                                학우들과 선배님들을 많이 만날 수 있었습니다. 이와 더불어 YIG는 고려대학교 RISK, 이화여자대학교 EIA, 서강대학교
                                                SRS, 성균관대학교 STAR 등의 학회들과 교류하며 외적인 네트워킹 또한 견고히 하고 있습니다.
                                            </p>
                                            <p>
                                                YIG는 여러분의 미래에 투자하고자 합니다.
                                                투자와 리서치에 관심이 있으신 분이면 누구나 환영합니다.
                                            </p>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>
                </Grid>
            </div>)
* */


const CardExampleCard = () => (
    <Card>
        <Image src={Professor} wrapped ui={false} size={"small"}/>
        <Card.Content>
            <Card.Header>한재훈 교수</Card.Header>
            <Card.Meta>
                <span className='date'>지도교수 / 경영대학</span>
            </Card.Meta>
        </Card.Content>
    </Card>
);

const MessageExampleMessage = () => (
    <div>
        <div style={{color:'#FFFFFF'}}>
            <p>
                안녕하십니까, 연세대학교 실전 가치투자학회 YIG 44기 회장 정종혁입니다.
            </p>
            <p>
                YIG는 2003년 설립된 연세대학교 유일의 실전 가치투자 학회로, 학문적 깊이와 실전 경험을 겸비한 가치투자의 실현을 목표로 하고 있습니다. 저희가 지향하는 ‘가치투자’란 투자 대상의 내재가치를 심층적으로 분석하고, 안전마진을 바탕으로 성과를 창출하는 투자 방식을 의미합니다.
            </p>
            <p>
                YIG 학회원들은 철저한 산업 및 기업 분석을 통해 투자 포인트를 발굴하고, 합리적인 추정과 밸류에이션 과정을 통해 적정 주가를 산출하며 투자 의견을 제시합니다. 더 나아가 학회원들은 이러한 분석을 토대로 국내 대학 최대 규모의 YIG 펀드를 직접 운용하며 실전 경험을 쌓고 있습니다.
            </p>
            <p>
                또한, YIG는 300여 명 이상의 Alumni와 함께 탄탄한 네트워크를 보유하고 있습니다. YIG의 네트워크는 학회원들의 소중한 자산으로서, 학회원들은 이 네트워크를 통해 현업에 계신 선배님과 교류를 갖고 가르침을 얻으며 주식 투자에 관한 시야를 넓혀가고 있습니다.
            </p>
            <p>
                주식 투자에 관한 관심과 열정이 넘치시는 분이라면, 주저하지 말고 YIG에 지원하시기 바랍니다. YIG는 여러분의 꿈과 미래에 투자하겠습니다.
            </p>
            <p>
                감사합니다.
            </p>
        </div>
    </div>
);
