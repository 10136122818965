import * as React from 'react';
import { Embed } from 'semantic-ui-react'
import { Header, Grid, Segment, Divider } from 'semantic-ui-react'
import { Button, Icon, Step } from "semantic-ui-react";
import api from '../../api';
import {CSSProperties} from "react";
import {SectionHeader, SectionHeaderWithSpace} from "../common/SectionHeader";

interface IMessage {
    headerMessage: string;
    description: string;
}

interface IInformation {
    title: string;
    description: string;
}

interface IState {
    processMessage: IMessage [];
    whatWeAreLookingFor: string [];
    recruitingInformation: IInformation [];
    application_form: string;
}

export default class Process extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            processMessage: [],
            whatWeAreLookingFor: [],
            recruitingInformation: [],
            application_form: "https://storage.googleapis.com/ebitda/media/application_forms/2025-02/%5B%E1%84%92%E1%85%A9%E1%86%BC%E1%84%80%E1%85%B5%E1%86%AF%E1%84%83%E1%85%A9%E1%86%BC%5D%20YIG_%E1%84%8C%E1%85%B5%E1%84%8B%E1%85%AF%E1%86%AB%E1%84%89%E1%85%A5_2025%20-%201.docx"
            ,
        };
    }

//     1	2020-08-29 13:08:39.978985+00	2021-08-19 02:10:05.517844+00	t	지원서 마감	1	9월 1일(수) 23:59
// 3	2020-08-29 13:13:00.566771+00	2021-08-19 02:10:42.229705+00	t	합격자 발표	3	9월 6일(월)
// 2	2020-08-29 13:12:47.585218+00	2021-08-19 02:10:52.518858+00	t	면접	2	9월 4일(토), 9월 5일(일)

    private setMessages = () => {
        // api.getRecruitingProcess().then(res => {
            const processMessage = []
            for (let processData of [{"process_message": "2월 14일", "description": "온라인 설명회"}, {"process_message": "2월 16일", "description": "지원서 마감"}, {"process_message": "2월 22일/23일", "description": "면접"}]) {
                processMessage.push(
                    {
                        headerMessage: processData.process_message,
                        description: processData.description,
                    }
                )
            this.setState({processMessage})
            }
        // })

//         2	학과, 학번, 나이에 무관하게 주식 투자에 관심과 경험이 많으신 분 (대학원생 포함)	2020-08-29 13:15:47.332786+00	2020-08-29 13:15:47.332811+00	t	2
// 3	정규 2학기, 방학 1학기 활동 가능하신 분	2020-08-29 13:15:59.887678+00	2020-08-29 13:15:59.887702+00	t	3
// 4	재무, 회계 지식을 바탕으로 기업 및 산업을 분석하고 싶으신 분	2020-08-29 13:16:05.488988+00	2020-08-29 13:16:05.48901+00	t	4
// 1	펀드 운용과 리서치 분야 진로에 관심이 있으신 분	2020-08-29 13:15:35.635411+00	2020-08-30 01:00:05.556768+00	t	1

        // api.getRecruitingCriterion().then(res => {
            const whatWeAreLookingFor = []
            for (let criterionData of [
                {"description": "주식 투자 및 운용에 관심이 있는 분"},
                {"description": "금융업계 커리어를 계획하고 있는 분"},
                {"description": "방학 포함 총 3학기 활동이 가능한 분"},
                {"description": "수,토요일 정규 세션에 참여 가능한 분"},
            ]) {
                whatWeAreLookingFor.push(
                    criterionData.description
                )
            this.setState({whatWeAreLookingFor})
            }
        // })

        // api.getRecruitingInformation().then(res => {
            const recruitingInformation = []
            for (let informationData of [
                {"title": "온라인 설명회", "description": "2월 14일\r\n※ 설명회는 ZOOM을 통해 한 시간 가량 비대면으로 진행될 예정입니다. \r\n※ 설명회 참여시 서류전형 가산점과 면접에 대한 힌트를 제공합니다."},
                {"title": "서류 접수", "description": "2월 3일~2월 16일\r\n지원서 링크(bit.ly/40XlXW7)를 통해 지원서 작성 후 아래 메일로 제출\r\n※ yig.recruiting.application@gmail.com"},
                {"title": "1차 면접: Stock Pitch", "description": "2월 22일"},
                {"title": "2차 면접: Fit", "description": "2월 23일"},
                {"title": "신입회원 OT", "description": "2월 26일"},
            ]) {
                recruitingInformation.push({
                    'title': informationData.title,
                    'description': informationData.description,
                }
                )
            this.setState({recruitingInformation})
            }
        // })

        // api.getApplicationForm()
        // .then(res => {
        //     this.setState({application_form: res.data.form})
        //     }
        // ).catch(error => {
        //     this.setState({application_form: ""})
        //     }
        // )
    }

    public componentDidMount(): void {
        this.setMessages()
    }

    private renderProcess() {
        const sectionCSS: CSSProperties = {
            marginLeft: "1em",
            marginRight: "1em"
        }

        return (
            <div className="section">
                <SectionHeader title={"Process"}/>
                <div className="section" style={sectionCSS}>
                    <Step.Group widths={3}>
                        { this.state.processMessage.map( message => {
                            return (
                                <MessageBox headerMessage={message.headerMessage}
                                            description={message.description}/>
                            )
                        })}
                    </Step.Group>
                </div>
            </div>
        )
    }

    private renderWhatWeAreLookingFor() {
        const dividerCSS: CSSProperties = {
            border: "0px",
            boxShadow: "0 0 0 0",
            marginLeft: "1em",
            marginRight: "1em"
        }

        return (
            <div className="section">
                <SectionHeaderWithSpace title={"What We Are Looking For"}/>
                <Segment style={ dividerCSS }>
                    <Grid stackable columns={2} relaxed='very'>
                        {/* <Grid.Column>
                            <YouTube videoId='3GN08V7Shhg'
                                     opts={{width: "100%"}}/>
                        </Grid.Column> */}
                        <Grid.Column verticalAlign="middle">
                            { this.state.whatWeAreLookingFor.map( attribute => {
                                return (<p><li>{attribute}</li></p>)
                            })}
                        </Grid.Column>
                    </Grid>
                </Segment>
            </div>
        )
    }

    private renderDownloadButton() {
        const buttonCSS: CSSProperties = {
            marginLeft: "1em",
            marginRight: "1em"
        }

        return (
            <div className="section" style={buttonCSS}>
                <a href={this.state.application_form}>
                    <Button fluid color="facebook">
                        <Button.Content visible>지원서 다운로드</Button.Content>
                    </Button>
                </a>
            </div>
        )
    }

    private renderDescriptionBox() {

        const textCSS: CSSProperties = {
            marginLeft: "1em",
            marginRight: "1em",
            marginTop: "1em"
        }

        return (
            <div style={textCSS}>
                {this.state.recruitingInformation.map( information => {
                    return(
                        <DescriptionBox title={information.title}
                                        description={information.description}/>
                    )
                })}
            </div>
        )
    }

    public render() {
        return (
            <div className="section">
                { this.renderProcess() }
                { this.renderWhatWeAreLookingFor() }
                { this.renderDownloadButton() }
                { this.renderDescriptionBox() }
            </div>
        )
    }

}

export const MessageBox = (props:{headerMessage: string, description: string}) => {
    return (
        <Step active>
            <Icon name="clipboard outline"/>
            <Step.Content>
                <Step.Title>{props.headerMessage}</Step.Title>
                <Step.Description>{props.description}</Step.Description>
            </Step.Content>
        </Step>
    )
}

export const DescriptionBox = (props:{title: string, description: string}) => {
    return (
        <Header as='h3'>
            {props.title}
            <Header.Subheader>
                {props.description.split("\n").map( message => {
                    return(
                        <ul>{message}</ul>
                    )
                })}
            </Header.Subheader>
        </Header>
    )
}