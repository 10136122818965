import * as React from 'react';
import { Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import Container from "semantic-ui-react/dist/commonjs/elements/Container";
import Logo from '../../asset/image/logo.png';
import './Footer.css';

interface IState {
    footerHTMLs?: string;
}


export default class Footer extends React.Component<{}, IState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            footerHTMLs: undefined
        }
    }

    public componentDidMount(): void {
        this.setFooters()
    }

    public render() {
        if (this.state.footerHTMLs == null) {
            return null
        }

        return (
            <Segment inverted vertical style={{ padding: '5em 3em' }}>
                <Container>
                    <Grid divided inverted stackable textAlign={"center"}>
                        <Grid.Row>
                            <Header inverted as='h2' content='연세대학교 가치투자학회 YIG' textAlign={"center"} />
                        </Grid.Row>
                        <Grid.Row>
                            <Header inverted as='h2' content='Yonsei Investment Group' textAlign={"center"} />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content={'지도교수  한재훈'} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content='회장  정종혁  010-8001-0347' />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Header inverted as='h4' content='HR  최지윤  010-3724-0178' />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <div className={'contacts'}>
                                <a href={'https://www.instagram.com/yig_yonsei/'} target="_blank"
                                    style={{ color: 'white', textDecoration: 'none', marginRight: '10px' }}>
                                    <Icon name='instagram' link={true} size={"large"} />
                                </a>
                                <span>
                                    yigyonsei@gmail.com
                                </span>
                            </div>
                        </Grid.Row>
                    </Grid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h5' inverted={true} textAlign={"right"}>
                                    <Image src={Logo} />
                                    Copyright ⓒ 2003 Yonsei Investment Group. All rights are reserved.
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Segment>
        )
    }

    private setFooters = () => {
        const footers = this.getFooters();
        this.setState({
            footerHTMLs: footers
        })
    };

    private getFooters = () => {
        return (
            '<div class="ui vertically divided grid center aligned remove-border">' +
            '<div class="ui center aligned container">' +
            '<div class="ui inverted stackable divided grid">' +
            '<div class="three wide column">' +
            '<h4 class="ui inverted header">Group 1</h4>' +
            '<div role="list" class="ui inverted link list">' +
            '<a role="listitem" class="item">Link One</a>' +
            '<a role="listitem" class="item">Link Two</a>' +
            '<a role="listitem" class="item">Link Three</a>' +
            '<a role="listitem" class="item">Link Four</a>' +
            '</div>' +
            '</div>' +
            '<div class="three wide column">' +
            '<h4 class="ui inverted header">Group 2</h4>' +
            '<div role="list" class="ui inverted link list">' +
            '<a role="listitem" class="item">Link One</a>' +
            '<a role="listitem" class="item">Link Two</a>' +
            '<a role="listitem" class="item">Link Three</a>' +
            '<a role="listitem" class="item">Link Four</a>' +
            '</div>' +
            '</div>' +
            '<div class="three wide column">' +
            '<h4 class="ui inverted header">Group 3</h4>' +
            '<div role="list" class="ui inverted link list">' +
            '<a role="listitem" class="item">Link One</a>' +
            '<a role="listitem" class="item">Link Two</a>' +
            '<a role="listitem" class="item">Link Three</a>' +
            '<a role="listitem" class="item">Link Four</a>' +
            '</div>' +
            '</div>' +
            '<div class="seven wide column">' +
            '<h4 class="ui inverted header">Footer Header</h4>' +
            '<p>Extra space for a call to action inside the footer that could help re-engage users.</p>' +
            '</div></div><div class="ui inverted section divider"></div><img src="https://react.semantic-ui.com/logo.png" class="ui mini centered image"><div role="list" class="ui small divided horizontal inverted link list"><a role="listitem" class="item" href="#">Site Map</a><a role="listitem" class="item" href="#">Contact Us</a><a role="listitem" class="item" href="#">Terms and Conditions</a><a role="listitem" class="item" href="#">Privacy Policy</a></div></div>' +
            '</div>'
        );
        // '<div class="ui vertically divided grid center aligned remove-border">' +
        //     '<div class="column row remove-border">' +
        //         '<div class="column remove-border">' +
        //           '<div>연세대학교 가치투자학회 YIG</div>' +
        //         '</div>' +
        //     '</div>' +
        //     '<div class="column row remove-border">' +
        //         '<div class="column  remove-border">' +
        //             '<div>Yonsei Investment Group</div>' +
        //         '</div>' +
        //     '</div>' +
        //     '<div class="column row">' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //     '</div>' +
        //     '<div class="two column row">' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //     '</div>' +
        //     '<div class="two column row">' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //     '</div>' +
        //     '<div class="two column row">' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //         '<div class="column">' +
        //             '<div>지도교수</div>' +
        //         '</div>' +
        //     '</div>' +
        // '</div>'
    };
}

